var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',[_vm._v(" Form Validation ")]),_c('v-card-text',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('validation-provider',{attrs:{"name":"Name","rules":{
              required: true,
              max: 20
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":20,"label":"Name","error-messages":errors},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Phone Number","rules":{
              required: true,
              numeric: true,
              digits: 11,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":11,"label":"Phone Number","error-messages":errors},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"E-mail","rules":{
              required: true,
              email: true
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"E-mail","error-messages":errors},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"select","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.items,"label":"Select","error-messages":errors},model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"checkbox","rules":{
              required: true
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"value":"1","label":"Option","type":"checkbox","required":"","error-messages":errors},model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}})]}}],null,true)}),_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","color":"primary","disabled":invalid}},[_vm._v(" submit ")]),_c('v-btn',{on:{"click":_vm.clear}},[_vm._v(" clear ")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }